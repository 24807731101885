require('./bootstrap');

$(function() {
    $('#keyword').focus();
    var string = $('#keyword').val();
    $('#keyword').get(0).setSelectionRange(string.length, string.length);

    require('jquery-ui/ui/widgets/autocomplete.js');
    $('#keyword').autocomplete({
        source: function(request, response) {
            $.ajax({
                type: 'GET',
                url: '/snippet.json',
                data: { keyword: request.term },
                dataType: 'json',
                autoFocus: true,
                success: function(data) {
                    response(data);
                }
            });
        },
        delay: 100,
        select: function( event, ui ) {
            this.form.submit();
        }
    });
});
